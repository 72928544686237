<template>
  <div>
    <a-skeleton v-if="loading" active class="details h-full p8" />

    <div class="details h-full" v-else>
      <div class="details-inner">
        <router-link
          class="details-header"
          :to="{
            name: 'Voyage',
            params: {
              id: voyage.voyage_id || 0,
              tab: 'weather',
              filter: 'chart',
            },
          }"
        >
          <a-typography-title :level="3">
            {{ voyage.departure_port_name }} - {{ voyage.arrival_port_name }}
          </a-typography-title>
          <img :src="icons.arrowRight" alt="arrow" />
        </router-link>

        <div class="details-wrapper">
          <perfect-scrollbar>
            <div class="chart">
              <vue-echarts
                ref="chartRef"
                v-if="voyage.speed_profile"
                :option="chartOption"
                :style="`height: 200px`"
                autoresize
              />
            </div>

            <div class="details-body">
              <a-typography-title :level="4">Voyage KPIs</a-typography-title>

              <ul class="details-list">
                <li>
                  <a-typography-text type="secondary">CII</a-typography-text>
                  <a-typography-text>
                    {{ kFormatter.format(voyage.cii) }}
                  </a-typography-text>
                </li>

                <li>
                  <a-typography-text type="secondary">{{ $t('history-page.in ECO') }}</a-typography-text>
                  <a-typography-text> N/A </a-typography-text>
                </li>

                <li>
                  <a-typography-text type="secondary">
                    {{ $t('history-page.in good weather') }}
                  </a-typography-text>
                  <a-typography-text>
                    {{ kFormatter.format(voyage.in_good_weather, "%") }}
                  </a-typography-text>
                </li>

                <li>
                  <a-typography-text type="secondary">
                    {{ $t('history-page.adverse weather') }}
                  </a-typography-text>
                  <a-typography-text>
                    {{ kFormatter.format(voyage.in_heavy_weather, "%") }}
                  </a-typography-text>
                </li>
              </ul>

              <a-typography-title :level="4"
                >SEEMP: Efficiency loss factors</a-typography-title
              >

              <ul class="details-list">
                <li>
                  <a-typography-text type="secondary">
                    {{ $t('history-page.RPM/power profile') }}
                  </a-typography-text>
                  <a-typography-text type="danger">
                    {{ kFormatter.format(voyage.rpm_power_profile, "%") }}
                  </a-typography-text>
                </li>

                <li>
                  <a-typography-text type="secondary">
                    {{ $t('history-page.JIT arrival') }}
                  </a-typography-text>
                  <a-typography-text type="danger">
                    {{ kFormatter.format(voyage.jit_arrival, "%") }}
                  </a-typography-text>
                </li>

                <li>
                  <a-typography-text type="secondary">
                    {{ $t('history-page.hull propeller') }}
                  </a-typography-text>
                  <a-typography-text type="secondary">N/A</a-typography-text>
                </li>
              </ul>
            </div>

            <div class="details-footer">
              <router-link to="/" class="detailed-report"
                >{{$t('history-page.detailed report')}} <RightOutlined
              /></router-link>
            </div>
          </perfect-scrollbar>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { defineComponent, watchEffect, reactive, computed, ref } from "vue";
import { VueEcharts } from "vue3-echarts";
import { RightOutlined } from "@ant-design/icons-vue";
import { format } from "date-fns";

import { icons } from "@/assets";
import { date } from "@/helpers";
import { kFormatter } from "@/helpers";

export default defineComponent({
  name: "HistoryDetails",

  props: {
    voyage: {
      type: Object,
      default: () => {},
    },
    loading: {
      type: Boolean,
      default: true,
    },
  },

  setup(props) {
    const chartRef = ref(null);

    const chart = reactive({
      option: {
        grid: {
          top: "10%",
          left: "10%",
          right: "5%",
          bottom: "20%",
        },
        xAxis: {
          data: [],
          axisLabel: {
            show: true,
            color: "#666971",
            fontSize: 14,
            formatter: (value) => `${format(new Date(value), "dd MMM")}`,
          },
          axisTick: {
            show: false,
          },
          axisLine: {
            show: false,
          },
        },
        yAxis: {
          type: "value",
          boundaryGap: true,
          axisLine: { onZero: false },
          axisLabel: {
            show: true,
            color: "#666971",
            fontSize: 14,
          },
          splitLine: {
            lineStyle: {
              color: "rgba(75,76,83, 0.5)",
            },
          },
        },
        visualMap: {
          right: -100,
          pieces: [
            {
              gt: 6,
              lte: 9,
              color: "#D45860",
            },
            {
              gt: 9,
              color: "#60B6E7",
            },
          ],
        },
      },
    });

    const chartOption = computed(() => chart.option);

    watchEffect(() => {
      if (props.voyage.speed_profile) {
        const { speed_profiles } = props.voyage.speed_profile;


        console.log("speed_profiles", speed_profiles)
        chart.option.xAxis.data = speed_profiles.actual_full_route.map(
          (item) => [date.formatFromUnixUTC(item[0], "dd MMM yyyy HH:mm:ss")]
        );

        chart.option.series = [
          {
            type: "line",
            symbolSize: 0,
            lineStyle: {
              color: "#F19AFF",
              type: "dashed",
              width: 1,
            },
            data: [
              [0, speed_profiles.optimal_by_rpm[0][1]],
              [
                date.formatFromUnixUTC(
                  speed_profiles.optimal_by_rpm[1][0],
                  "dd MMM yyyy HH:mm:ss"
                ),
                speed_profiles.optimal_by_rpm[0][1],
              ],
            ],
          },
          {
            type: "line",
            symbolSize: 0,
            lineStyle: {
              color: "#81D862",
              type: "dashed",
              width: 1,
            },
            data: [
              [0, speed_profiles.optimal_by_jit[0][1]],
              [
                date.formatFromUnixUTC(
                  speed_profiles.optimal_by_jit[1][0],
                  "dd MMM yyyy HH:mm:ss"
                ),
                speed_profiles.optimal_by_jit[0][1],
              ],
            ],
          },
          {
            type: "line",
            symbolSize: 4,
            symbol: "circle",
            data: speed_profiles.actual_full_route.map((item) => item[1]),
          },
        ];

        chart.option.visualMap = {
          right: -100,
          pieces: [
            {
              gt: 0,
              lte: speed_profiles.optimal_by_rpm[0][1],
              color: "#D45860",
            },
            {
              gt: speed_profiles.optimal_by_rpm[0][1],
              color: "#60B6E7",
            },
          ],
        };

        if (chartRef.value) {
          chartRef.value.refreshOption();
        }
      }
    });

    return {
      icons,
      kFormatter,
      chartOption,
      chartRef,
    };
  },

  components: {
    VueEcharts,
    RightOutlined,
  },
});
</script>

<style lang="less" scoped>
.p8 {
  padding: 8px;
}

.h-full {
  height: 100%;
}

.ps {
  height: 100%;
}

.details {
  width: 43.5rem;
  background-color: @color-white-panel;
  box-shadow: -0.3rem 0 0.6rem rgba(@color-black, 0.25);
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  position: relative;
  z-index: 2;

  &-wrapper {
    height: calc(100vh - 24.4rem);
  }

  &-header {
    height: 10rem;
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 2.5rem;

    h3.ant-typography {
      line-height: 1.2;
      font-weight: 400;
    }
  }

  &-body {
    padding: 0.0rem 2.5rem;

    h4.ant-typography {
      font-size: 1.8rem;
      margin-bottom: 12px;
    }
  }

  &-footer {
    padding: 3.2rem 2.5rem;

    .detailed-report {
      text-transform: uppercase;

      .anticon {
        color: @color-secondary;
      }
    }
  }

  &-list {
    list-style: none;
    margin-bottom: 5.4rem;
    padding: 0;

    li {
      border-bottom: 0.1rem dashed @color-border;
      font-size: 1.6rem;
      display: flex;
      align-items: center;
      justify-content: space-between;
      margin: 0.8rem 0 0 2rem;

      .ant-typography {
        background-color: @color-white-panel;
        padding: 0 0.5rem;
        top: 0.8rem;
        position: relative;
      }
    }
  }
}

.chart {
  background-color: @color-body;
  height: 20rem;
}
</style>
